<template>
  <div
    class="userservic"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="菜单名称"></el-table-column>
        <el-table-column prop="url" label="菜单图标">
          <template slot-scope="scope">
            <el-button size="mini" @click="iconImg(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="jumpUrl" label="跳转路径"></el-table-column>
        <el-table-column prop="openStatus" label="启用状态">
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-if="scope.row.openStatus == 0"
              @click="openS(scope.$index, scope.row)"
              >禁用</el-button
            >
            <el-button
              size="mini"
              v-if="scope.row.openStatus == 1"
              @click="openS(scope.$index, scope.row)"
              >启用</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 下边 -->
    <div class="bottom">
      <div class="bottom-right">
        <!-- <div class="buttons">
          <el-button>数据分析</el-button>
          <el-button>数据导出</el-button>
        </div> -->
      </div>
    </div>
    <!-- 修改按钮的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      width="50%"
      :before-close="handleClose1"
    >
      <el-form :model="formInline" class="demo-form-inline" label-width="76px">
        <el-form-item label="菜单名称">
          <el-input
            v-model="formInline.name"
            placeholder="输入菜单名称"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="图标路径">
          <el-button
            type="primary"
            plain
            @click="pushImage1"
            style="display: block; margin-bottom: 3%"
            >上传图片</el-button
          >
          <img :src="this.imageUrl" style="width: 200px" id="gengaiAdvertImg" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="quitAddListChange">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible1"
      width="30%"
      :before-close="handleCloseImage1"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange1" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 图片回显的弹框 -->
    <el-dialog
      title="产品图片"
      :visible.sync="dialogVisibleImage"
      width="30%"
      :before-close="ImagehandleClose"
    >
      <!-- <img src="http://cdn.careld.cn/datacenter/menu/product/91638329205408.png" alt=""> -->
      <el-image
        style="width: 100%; height: auto; padding-bottom: 30px"
        :src="advertImage"
      ></el-image>
    </el-dialog>
    <el-dialog
        title="查看图标"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <span
          ><img style="width: 100%; margin: 0 auto" :src="photoUrls"
        /></span>
      </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      dialogVisible1: false, //修改按钮的弹框
      imageVisible1: false,
      dialogVisibleImage: false,
      centerDialogVisible: false,
      photoUrls:"",
      tableData: [],
      formInline: {},
      imageUrl: "",
      //七牛配置
      token: "",
      token1: "",
      accessKey1: "",
      bucket1: "",
      secretKey1: "",
      prefix1: "",
      advertIds: "",
      advertImage: "",
      photoUrl: "",
      flag: false,
    };
  },
  created() {
    //查询列表
    this.advertiseList();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //查询图标
    iconImg(row) {
      this.centerDialogVisible = true;
      this.photoUrls = row.url;
    },
    //列表修改状态
    openS(index, row) {
      this.$confirm("此操作将修改启用状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.openStatus == 0) {
            row.openStatus = 1;
          } else {
            row.openStatus = 0;
          }
          apiShout
            .updateMenu({ id: row.id, openStatus: row.openStatus })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.advertiseList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //修改
    handleEdit(index, row) {
      this.dialogVisible1 = true;
      this.advertIds = row.id;
      this.formInline = row;
      this.imageUrl = row.url;
    },
    //修改的确定
    quitAddListChange() {
      this.dialogVisible1 = false;
      //获取本地
      let proPh = JSON.parse(localStorage.getItem("productPhoto"));
      if (this.flag == true) {
        this.photoUrl = "http://cdn.careld.cn/" + proPh;
      } else {
        this.photoUrl = this.imageUrl;
      }

      let obj = {
        id: this.advertIds,
        name: this.formInline.name,
        url: this.photoUrl,
      };
      apiShout.updateMenu(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          //刷新页面
          this.advertiseList();
          this.flag = false;
          //清空表单
          this.advertIds = "";
          this.formInline = {};
          this.imageUrl = "";
        }
      });
    },
    //修改点击上传图片的按钮
    pushImage1() {
      this.imageVisible1 = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "rbtmenu" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token1 = res.data.result.token;
        this.accessKey1 = res.data.result.accessKey;
        this.bucket1 = res.data.result.bucket;
        this.secretKey1 = res.data.result.secretKey;
        this.prefix1 = res.data.result.prefix;
      });
    },
    //修改确定上传图片
    uploadImage1() {
      this.imageVisible1 = false;
      this.flag = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      this.fileList = [];
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      let bIm = JSON.parse(localStorage.getItem("advertBackImage1"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token1 = this.token1;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      const observable = qiniu.upload(
        file,
        this.prefix1 + this.id + shijiancuo + ming,
        token1,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("productPhoto", JSON.stringify(res.key));
          // this.photoUrl = res.key;
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          loading.close();
          document.getElementById("gengaiAdvertImg").src = bIm;
          //存本地
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //修改上传头像格式限制
    fileChange1(e) {
      //格式限制
      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //保存本地
          localStorage.setItem(
            "advertBackImage1",
            JSON.stringify(e.target.result)
          );
        };
      }
      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = e.target.files[0].size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
    },
    //修改弹框的x
    handleClose1() {
      this.dialogVisible1 = false;
      //清空表单
      //清空表格
      this.formInline = {};
      this.imageUrl = "";
    },
    ImagehandleClose() {
      this.dialogVisibleImage = false;
    },
    handleCloseImage1() {
      this.imageVisible1 = false;
    },
    //查询列表
    advertiseList() {
      apiShout.getMenuList().then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
}
.userservic {
  .search {
    height: auto;
    margin: 0 40px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    .span1 {
      display: block;
      font-size: 14px;
      color: #909399;
      font-weight: bold;
      width: 80px;
      text-align: center;
    }
    .el-select {
      margin-right: 60px;
      margin-left: 10px;
    }
    .el-input {
      width: 120px;
      margin-left: 10px;
    }
    .in1 {
      margin-right: 10px;
    }
    .in2 {
      margin-right: 10px;
    }
  }
  // table
  .tables {
    margin-left: 30px;
    margin-right: 40px;
    margin-top: 3%;
    .el-table::before {
      height: 0;
    }
    .el-table {
      // td,
      // th.is-leaf {
      //   border: none !important;
      // }
      /deep/.el-table td.el-table__cell,
      .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
      /deep/.el-table__cel {
        text-align: center;
      }
      /deep/.cell {
        text-align: center;
      }
      tr th {
        font-size: 19px;
        color: #a8a8a8;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 17px;
        color: #a8a8a8;
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
  }
  // 下边
  .bottom-right {
    float: right;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    .el-button {
      width: 130px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      margin-top: 20px;
      margin-right: 20px;
      background: #d78673;
      color: #ffffff;
      font-size: 16px;
      padding: 0px;
    }
  }

  .top-btn {
    background: #d78673;
    margin-left: 30px;
    color: #ffffff;
  }
  .bottom-fen {
    margin-top: 10px;
  }
  .bottom-left {
    float: left;
  }
}
//弹框
/deep/ .el-dialog__body {
  margin: 20px;
}
/deep/.el-form-item__label {
  color: #909399;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>